.cards{
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    align-items: center;
    
}
.containerr{
     /* background:url('https://wellimaginethat.com.au/wp-content/uploads/2016/11/purple-background.jpg'); */
    
     background: linear-gradient(to right, #07105f 0%, #5ca9fb 100%);
    /* background-color: blueviolet; */
}
.cards__part1,.cards__part2{
    display: flex;
    flex-direction: row;
    /* margin-top: 2rem; */
}
@media screen and (max-width:1025px){
    .cards{
        display: flex;
        flex-direction: column;
    }
    .cards__part1,.cards__part2{
        display: flex;
        flex-direction: column;
    }
}